import React, { useState, useRef, useEffect } from "react";
import { saveKeywords } from "../../core/Api/customer";
import { toast } from "react-toastify";
import UserInput from "../../components/Inputs";
import CheckBoxInput from "../../components/Inputs/CheckBox";
import { Button, Col, Modal } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import { getAllPartners, getPartners, getPartnersList, setFeaturedSalon } from "../../core/Api/partner";
import { useTranslation } from "react-i18next";
import CustomDropdown from "../../components/Dropdown";
import FullPageSpinner from "../../components/FullPageSpinner";
import DateInput from "../../components/DateInput";
import moment from "moment";

const FeaturedSalonsForm = ({ handleClose, selectedSupplier }) => {
  const { t } = useTranslation();
  const simpleValidator = useRef(new SimpleReactValidator({
    forceUpdate: true,
    validators: {
      notPastDate: {
        message: t("Must not contains past date"),
        rule: (val) => {
          if (moment(val).startOf('day') < moment().startOf('day')) return false;
          else return true
        },
        required: true,
      },
    }
  }));
  const [supplierDetails, setSupplierDetails] = useState({ ...selectedSupplier });
  const [isLoading, setLoading] = useState(false);
  const [, forceUpdate] = useState(0);
  const [suppliers, setSuppliers] = useState([
    { value: null, label: 'Select Supplier' }
  ]);

  useEffect(() => {
    loadData();
  }, [])

  const loadData = async () => {
    setLoading(true);
    const res = await getPartnersList({
      per_page: 99999,
      offset: 0
    });
    setLoading(false);
    if (res?.code === 200) {
      setSuppliers([
        ...suppliers,
        ...(res?.result || []).map(r => ({
          label: r?.name,
          value: r?.supplier_id
        }))
      ])
    }
  }

  const handleDDChange = (value) => {
    setSupplierDetails({
      ...supplierDetails,
      supplier_id: value?.value
    })
  }

  const handleDateChange = (date, name) => {
    setSupplierDetails({
      ...supplierDetails,
      [name]: new Date(date),
    });
  };

  const handleSaveOffer = async () => {
    toast.dismiss();
    try {
      if (simpleValidator.current.allValid()) {
        if (moment(supplierDetails?.end_date).startOf('day') < moment(supplierDetails?.start_date).startOf('day')) {
          toast.error('End date of must be greater than or equals to start date');
          return
        }
        setLoading(true);
        const result = await setFeaturedSalon({
          supplier_featured_id: supplierDetails?.supplier_featured_id,
          supplier_id: supplierDetails?.supplier_id,
          start_date: moment(supplierDetails?.start_date).format('YYYY-MM-DD'),
          end_date: moment(supplierDetails?.end_date).format('YYYY-MM-DD')
        });
        setLoading(false);
        if (result.code === 200) {
          handleClose();
        } else {
          throw new Error(result?.message ?? "Something went wrong");
        }
      } else {
        forceUpdate(1);
        simpleValidator.current.showMessages();
        throw new Error("Please enter valid details" ?? "Something went wrong");
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message ?? "Something went wrong");
    }
  };

  return (
    <React.Fragment>
      <Modal.Body>
        {isLoading && <FullPageSpinner /> || null}
        <div className="add-fav-salon-cont">
          <Col>
            <CustomDropdown
              onChange={(value) =>
                handleDDChange(value, "supplier_id")
              }
              name="supplier_id"
              options={suppliers || []}
              label={t("Suppliers")}
              value={
                suppliers.find(
                  (row) => row.value == +supplierDetails?.supplier_id
                ) || ""
              }
            />
            {simpleValidator.current.message(
              "Supplier",
              supplierDetails?.supplier_id,
              "required"
            )}
          </Col>
          <Col className="mt-3">
            <DateInput
              label={t("From date")}
              value={new Date(moment(supplierDetails.start_date).format('YYYY/MM/DD'))}
              onChange={(date) => handleDateChange(date, "start_date")}
            />
            {simpleValidator.current.message(
              "start_date",
              supplierDetails?.start_date,
              "required|notPastDate"
            )}
          </Col>
          <Col className="mt-3">
            <DateInput
              label={t("To date")}
              value={new Date(moment(supplierDetails.end_date).format('YYYY/MM/DD'))}
              onChange={(date) => handleDateChange(date, "end_date")}
            />
          </Col>
        </div>
      </Modal.Body>
      <Modal.Footer className="footer">
        <Button
          disabled={isLoading}
          onClick={handleSaveOffer}
          className="save-btn"
        >
          Save changes
        </Button>
        <Button onClick={handleClose} variant="white">
          Close
        </Button>
      </Modal.Footer>
    </React.Fragment >
  );
};

export default FeaturedSalonsForm;
