import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import PrimaryButton from '../../components/Buttons/Primary';
import CustomDropdown from '../../components/Dropdown';
import DateInput from '../../components/DateInput';
import { Container, Row, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';
import Hoc from '../Hoc';
import './Styles.scss';
import { getCustomersList } from '../../core/Api/customer';
import {
	getBookingTransactions,
	getPartnersList,
} from '../../core/Api/partner';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import CustomTableComponent from '../../components/CustomTableComponent';
import Pagination from '../../components/Pagination';
import locationIcon from '../../assets/images/geo-alt-fill.svg';
import FullScreenPopup from '../../components/FullScreenPopup';
import { useHistory } from 'react-router-dom';
import FullPageSpinner from '../../components/FullPageSpinner';
import ViewAppointment from '../Client/Profile/ViewAppointment';
import { exportDataToExcel } from '../../utils/helper';
import { markAsPaid } from '../../core/Api/bookings';
import Swal from 'sweetalert2';

const Partners = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const rowRef = useRef(true);

	const offset = 0;
  const pageSize = 10;
	const [pageNo, setPageNo] = useState(1);
	const [totalCount, setTotalCount] = useState(0);
	const [customer, setCustomers] = useState([
		{ label: t('Select Customer'), value: null },
	]);
	const [partner, setPartners] = useState([
		{ label: t('Select Partner'), value: null },
	]);
	const [filterSchema, setFilterSchema] = useState({
		supplier_id: null,
		customer_id: null,
		from_date: null,
		to_date: null,
		app_from_date: null,
		app_to_date: null,
		is_paid: null,
	});
	const [isLoading, setIsLoading] = useState(false);
	const [csvLoading, setCSVLoading] = useState(false);
	const [transactions, setTransactions] = useState([]);
	const [viewAppointment, setViewAppointment] = useState(false);

	const statusOptions = [
		{ label: t('Status'), value: 0 },
		{ label: t('Cancelled'), value: 4 },
		{ label: t('Pending'), value: 5 },
		{ label: t('Completed'), value: 6 },
		{ label: t('Confirmed'), value: 10 },
		{ label: t('In Progress'), value: 13 },
		{ label: t('Created'), value: 14 },
	];

	const paidStatusOptions = [
		{ label: t('Please Select'), value: null },
		{ label: t('Paid'), value: 1 },
		{ label: t('UnPaid'), value: 0 },
	];

	useEffect(() => {
		(async () => {
			const [customers, partners] = await Promise.all([
				getCustomersList({
					per_page: 99999,
				}),
				getPartnersList(),
				loadData(pageSize, offset, filterSchema),
			]);
			if (customers && customers.code === 200) {
				if (customers.result) {
					const data = [...customer];
					customers?.result?.result_data.forEach((row) => {
						data.push({
							label: row.first_name,
							value: row.customer_id,
						});
					});
					setCustomers(data);
				}
			} else {
				toast.error(customers && customers.message);
			}
			if (partners && partners.code === 200) {
				if (partners.result) {
					const data = [...partner];
					partners.result.forEach((row) => {
						data.push({
							label: row.name,
							value: row.supplier_id,
						});
					});
					setPartners(data);
				}
			} else {
				toast.error(partners && partners.message);
			}
		})();
		// eslint-disable-next-line
	}, []);

	const loadData = async (pageSize, offset, filters) => {
		setIsLoading(true);
		const appliedFilters = {
			status_id: filters?.status_id || null,
			supplier_id: filters?.supplier_id || null,
			customer_id: filters?.customer_id || null,
			from_date:
				filters?.from_date && moment(filters?.from_date).format('YYYY-MM-DD'),
			to_date:
				filters?.to_date && moment(filters?.to_date).format('YYYY-MM-DD'),
			app_from_date:
				filters?.app_from_date &&
				moment(filters?.app_from_date).format('YYYY-MM-DD'),
			app_to_date:
				filters?.app_to_date &&
				moment(filters?.app_to_date).format('YYYY-MM-DD'),
		};
		if (filters?.is_paid !== null) {
			appliedFilters.is_paid = filters?.is_paid;
		}
		const result = await getBookingTransactions({
			...appliedFilters,
			per_page: pageSize,
			offset: offset,
		});
		if (result && result.code === 200) {
			setTransactions(result.result?.result_data);
			// setCurrTransactions(result?.result?.slice(0, 10) || []);
			// setPageNo(1);
			setTotalCount(result?.result?.total_count || 0);
		} else {
			toast.error(result && result.message);
		}
		setIsLoading(false);
	};

	const handleSearch = () => {
    setPageNo(1)
		loadData(pageSize,0,filterSchema);
	};

	const handleChange = (value, name) => {
		setFilterSchema({
			...filterSchema,
			[name]: value,
		});
	};

	const handleDateChange = (date, name) => {
		setFilterSchema({
			...filterSchema,
			[name]: (date && new Date(date)) || null,
		});
	};

	const handlePageChange = (pageNo, pageSize) => {
		setPageNo(pageNo);
		loadData(pageSize, (pageNo - 1) * pageSize, filterSchema);
	};

	const handleCSVExport = async () => {
		try {
			setCSVLoading(true);
      const appliedFilters = {
        status_id: filterSchema?.status_id || null,
        supplier_id: filterSchema?.supplier_id || null,
        customer_id: filterSchema?.customer_id || null,
        from_date:
          filterSchema?.from_date && moment(filterSchema?.from_date).format('YYYY-MM-DD'),
        to_date:
          filterSchema?.to_date && moment(filterSchema?.to_date).format('YYYY-MM-DD'),
        app_from_date:
          filterSchema?.app_from_date &&
          moment(filterSchema?.app_from_date).format('YYYY-MM-DD'),
        app_to_date:
          filterSchema?.app_to_date &&
          moment(filterSchema?.app_to_date).format('YYYY-MM-DD'),
      };
      if (filterSchema?.is_paid !== null) {
        appliedFilters.is_paid = filterSchema?.is_paid;
      }
      const result = await getBookingTransactions({
        ...appliedFilters,
        per_page: totalCount,
        offset: 0,
      });
      if (result && result.code === 200) {
        
			const bookings = result.result?.result_data ?? [];
			await exportDataToExcel({
				fileName: `Booking_Transactions_${new Date().toLocaleDateString(
					'en-US'
				)}`,
				header: [
					'Supplier Id',
					' Reference Number',
					' Customer',
					' Mobile',
					' Supplier',
					' Booking Type',
					'Booking From',
					'  Special Instructions',
					' Original Amount',
					' Amount',
					' Amount Diff.',
					' Payment Mode',
					' Promo Code',
					' Guest Booking',
					' Address',
					'Supplier City',
					' Created At',
					' Status',
				],
				data: bookings?.map((r) => ({
					supplier_id: `${r?.supplier_id}`,
					reference_number: `${r?.reference_number}`,
					customer_name: `${r?.customer_name}`,
					customer_mobile: `"${r?.customer_mobile}"`,
					supplier_name: `${r?.supplier_name}`,
					location_type: `${r?.location_type}`,
					application_type: formatBookingFrom(r, 'application_type'),
					notes: r?.notes ?? 'N/A',
					original_amount: `SAR ${r.original_amount}`,
					total_amount: `SAR ${r.total_amount}`,
					diff: `${r.original_amount - r.total_amount}`,
					payment_mode: getPaymentModes(r, 'payment_mode' || []),
					coupon_code: r?.offer_details?.coupon_code || '',
					is_guest_booking: r?.is_guest_booking === '1' ? 'Yes' : 'No',
					location: `${r?.location}`,
					supplier_city_name: `${r?.supplier_city_name}`,
					appointment_date: moment(r.date).format('YYYY-MM-DD'),
					status: `${r?.status}`,
				})),
			});
			setCSVLoading(false);
      } else {
        toast.error(result && result.message);
			setCSVLoading(false);

      }

		} catch (error) {
			toast.error(error && error.message);
			setCSVLoading(false);
		}
	};

	const onRowClick = (row, key) => {
		if (!rowRef.current) return;
		setViewAppointment(true);
		history.push(
			`${history?.location?.pathname}?booking_id=${row?.booking_id}`
		);
	};

	const closeOverlay = () => {
		setViewAppointment(false);
		history.push('/booking-transactions');
	};

	const formatCustomer = (row) => {
		return `${row?.customer_name}`;
	};

	const formatBookingFrom = (row, key) => {
		switch (row?.application_type?.toLowerCase()) {
			case 'customer/android':
				return 'Android';
			case 'partner/ios':
				return 'Partner/iOS';
			case 'customer/web':
				return 'Android';
			case 'customer/ios':
				return 'iOS';
			case 'backoffice':
				return 'Backoffice';
			default:
				return 'N/A';
		}
	};

	const getPaymentModes = (row) => {
		let mode = '';
		if (Array.isArray(row?.payment_mode) && row?.payment_mode.length) {
			row?.payment_mode?.forEach((r, i) => {
				mode += `${(i > 0 && ' - ') || ''}${r?.pay_mode?.toUpperCase()}`;
			});
		} else {
			mode = row?.payment_mode;
		}
		return mode;
	};

	const amountFormatter = (row, key) => {
		return <span className="text-nowrap">{`${row[key]} SAR`}</span>;
	};

	const renderGuestInfo = (props, row) => {
		return (
			<Tooltip {...props}>
				<div className="p-2">
					<div className="row">
						<div className="col-4 text-left text-nowrap">{t('Name')}</div>
						<div className="col-8 text-left text-nowrap">{row?.guest_name}</div>
					</div>
					<div className="row">
						<div className="col-4 text-left text-nowrap">{t('Mobile#')}</div>
						<div className="col-8 text-left text-nowrap">
							{row?.guest_mobile}
						</div>
					</div>
					<div className="row">
						<div className="col-4 text-left text-nowrap">{t('Email')}</div>
						<div className="col-8 text-left text-nowrap">
							{row?.guest_email}
						</div>
					</div>
				</div>
			</Tooltip>
		);
	};

	const guestInfoFormatter = (row) => {
		if (row?.is_guest_booking === '1') {
			return (
				<OverlayTrigger
					placement="bottom"
					delay={{ show: 100, hide: 1 }}
					overlay={(props) => renderGuestInfo(props, row)}
				>
					<p style={{ width: 'fit-content' }}>{t(`Yes`)}</p>
				</OverlayTrigger>
			);
		} else {
			return <p style={{ width: 'fit-content' }}>{t(`No`)}</p>;
		}
	};

	const addressFormatter = (row) => {
		if (!row.lat_lng) {
			return '';
		}
		return (
			<div
				className="addressWrapper"
				onClick={() => handleRedirectToLocation(row.lat_lng)}
			>
				<img src={locationIcon} alt="location" />
				<span>Map View</span>
			</div>
		);
	};

	const handleRedirectToLocation = (lat_lng) => {
		lat_lng = lat_lng.split(',').map((val) => val?.trim());
		window.open(
			`https://maps.google.com/maps?z=12&t=m&q=${lat_lng[0]}+${lat_lng[1]}`,
			'_blank'
		);
	};

	const dateFormatter = (row, key) => {
		return (
			<span className="text-nowrap">
				{moment(row[key]).format('YYYY-MM-DD')}
			</span>
		);
	};

	const statusFormatter = (row, key) => {
		const style = ((status) => {
			switch (status) {
				case 'In Progress':
					return {
						color: '#7effd7',
						fontFamily: 'Nunito Sans-extrabols',
						fontSize: '16px',
					};
				case 'Cancelled':
					return {
						color: '#dd3333',
						fontFamily: 'Nunito Sans-extrabold',
						fontSize: '16px',
					};
				case 'Completed':
					return {
						color: '#097969',
						fontFamily: 'Nunito Sans-extrabold',
						fontSize: '16px',
					};
				default:
					return {
						color: '#ffa470',
						fontFamily: 'Nunito Sans-extrabold',
						fontSize: '16px',
					};
			}
		})(row?.status);
		return (
			<div className="status-formatter" style={style}>
				{t(row?.status)}
			</div>
		);
	};

	const handlePaidStatusChange = async (params) => {
		rowRef.current = false;
		Swal.fire({
			text: t('Are you sure to mark the booking as Paid?'),
			showCancelButton: true,
			confirmButtonColor: '#d33',
			cancelButtonColor: '#3085d6',
			cancelButtonText: t('Cancel'),
			confirmButtonText: t('Yes'),
		}).then(async (result) => {
			if (result.isConfirmed) {
				setIsLoading(true);
				const res = await markAsPaid({
					booking_id: params?.booking_id,
					supplier_id: params?.supplier_id,
				});
				if (res?.status < 400) {
					rowRef.current = true;
					toast.success(t('statusUpdatedSuccessfully'));
					handleSearch();
				} else {
					rowRef.current = true;
					setIsLoading(false);
					toast.error(
						res?.message || t('Something went wrong.Please try again')
					);
				}
			} else {
				rowRef.current = true;
				return;
			}
		});
	};

	const isPaidFormatter = (row, key) => {
		if (row[key] === '1') {
			return (
				<div className="paid-status-cont">
					<b>{row[key] === '1' && 'PAID'}</b>
				</div>
			);
		} else if (row?.status === 'Completed') {
			return (
				<div className="markPaidCont">
					<PrimaryButton
						disabled={(isLoading && true) || false}
						onClick={() => handlePaidStatusChange(row)}
						text={t('Mark as Paid')}
						style={{ width: '100%' }}
						// isLoading={isLoading}
					/>
				</div>
			);
		} else {
			return (
				<div className="paid-status-cont">
					<b>{(row[key] === '1' && 'PAID') || 'Un PAID'}</b>
				</div>
			);
		}
	};

	const columns = [
		{
			name: t('Reference #'),
			key: 'reference_number',
			formatter: (row) => {
				return (
					<span
					// className="ref-no-cont"
					// onClick={() => onRowClick(row)}
					>
						{row?.reference_number}
					</span>
				);
			},
		},
		{
			name: t('Customer'),
			key: 'first_name',
			formatter: formatCustomer,
		},
		{
			name: t('Mobile'),
			key: 'customer_mobile',
			formatter: null,
		},
		{
			name: t('Supplier'),
			key: 'supplier_name',
			formatter: null,
		},
		{
			name: t('Booking Type'),
			key: 'location_type',
			formatter: null,
		},
		{
			name: t('Special Instructions'),
			key: 'notes',
			formatter: (row, key) => {
				if (!row[key]) {
					return <span>N/A</span>;
				}
				return (
					<OverlayTrigger
						placement="bottom"
						overlay={<Tooltip id="button-tooltip-2">{row[key]}</Tooltip>}
					>
						<span
							style={{
								maxWidth: '200px',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
								display: 'inline-block',
								cursor: 'pointer',
							}}
						>
							{row[key]}
						</span>
					</OverlayTrigger>
				);
			},
		},
		{
			name: t('Booking From'),
			key: 'application_type',
			// formatter: formatBookingFrom,
		},
		{
			name: t('Original Amount'),
			key: 'original_amount',
			formatter: amountFormatter,
		},
		{
			name: t('Amount'),
			key: 'total_amount',
			formatter: amountFormatter,
		},
		{
			name: t('Payment Mode'),
			key: 'payment_mode',
			formatter: getPaymentModes,
		},
		{
			name: t('Promo Code'),
			key: 'promo_code',
			formatter: (row) => {
				return <span>{row?.offer_details?.coupon_code}</span>;
			},
		},
		{
			name: t('Guest Booking'),
			key: 'is_guest_booking',
			formatter: guestInfoFormatter,
		},
		{
			name: t('Supplier City'),
			key: 'supplier_city_name',
		},
		{
			name: t('Address'),
			key: 'lat_lng',
			formatter: addressFormatter,
		},
		{
			name: t('Date Created'),
			key: 'date',
			formatter: dateFormatter,
		},
		// {
		//   name: t("Appointment Date"),
		//   key: 'appointment_date',
		//   formatter: dateFormatter,
		// },
		{
			name: t('Status'),
			key: 'status',
			formatter: statusFormatter,
		},
		{
			name: t('Paid Status'),
			key: 'is_paid',
			formatter: isPaidFormatter,
		},
	];

	return (
		<React.Fragment>
			<Hoc activeMenu="bookingTransactions">
				<Container fluid className="bookings">
					<Row className="heading-row">
						<Col lg="3" md="6" sm="12" className="mb-3">
							<DateInput
								label={t('From date')}
								value={filterSchema.from_date}
								onChange={(date) => handleDateChange(date, 'from_date')}
								isClearable={true}
							/>
						</Col>
						<Col lg="3" md="6" sm="12" className="mb-3">
							<DateInput
								label={t('To date')}
								value={filterSchema.to_date}
								onChange={(date) => handleDateChange(date, 'to_date')}
								isClearable={true}
							/>
						</Col>
						<Col lg="3" md="6" sm="12" className="mb-3">
							<DateInput
								label={t('Apt. from date')}
								value={filterSchema.app_from_date}
								onChange={(date) => handleDateChange(date, 'app_from_date')}
								isClearable={true}
							/>
						</Col>
						<Col lg="3" md="6" sm="12" className="mb-3">
							<DateInput
								label={t('Apt. to date')}
								value={filterSchema.app_to_date}
								onChange={(date) => handleDateChange(date, 'app_to_date')}
								isClearable={true}
							/>
						</Col>
						<Col lg="3" md="6" sm="12" className="mb-3">
							<CustomDropdown
								onChange={(value) => handleChange(value.value, 'supplier_id')}
								name="supplier_id"
								value={''}
								options={partner}
								label={t('Partner/Salon')}
							/>
						</Col>
						<Col lg="3" md="6" sm="12" className="mb-3">
							<CustomDropdown
								onChange={(value) => handleChange(value.value, 'customer_id')}
								name="customer_id"
								value={''}
								options={customer}
								label={t('Customer')}
							/>
						</Col>
						<Col lg="3" md="6" sm="12" className="mb-3">
							<CustomDropdown
								options={statusOptions}
								onChange={(value) => handleChange(value.value, 'status_id')}
								label={t('Booking Status')}
								value={
									statusOptions?.find(
										(r) => r?.value === filterSchema?.status_id
									) || null
								}
								name="status_id"
							/>
						</Col>
						<Col lg="3" md="6" sm="12" className="mb-3">
							<CustomDropdown
								options={paidStatusOptions}
								onChange={(value) => handleChange(value.value, 'is_paid')}
								label={t('Paid Status')}
								value={
									paidStatusOptions?.find(
										(r) => r?.value === filterSchema?.is_paid
									) || null
								}
								name="is_paid"
							/>
						</Col>
						<Col lg="3" md="6" sm="12" className="cta-col mt-3 mb-2">
							<PrimaryButton
								text={t('Search')}
								onClick={handleSearch}
								style={{ width: '100%', marginTop: '25px' }}
								disabled={isLoading}
							/>
						</Col>
						<Col lg="3" md="3" sm="12" className="mt-3 mb-2">
							<PrimaryButton
								disabled={csvLoading || !transactions?.length}
								onClick={handleCSVExport}
								text={t('Export as CSV')}
								style={{ width: '100%', marginTop: '25px' }}
								isLoading={csvLoading}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							{isLoading && (
								<div className="spinner-wrap">
									<FullPageSpinner animation="border" />
									<span>{t("Please wait, we're loading data")}</span>
								</div>
							)}
							{(!isLoading && transactions?.length === 0 && (
								<div className="no-data-text text-center mt-2">
									{t('No data available')}
								</div>
							)) ||
								null}
						</Col>
					</Row>
					<Row className="mt-3 table-cont">
						{(transactions?.length > 0 && (
							<CustomTableComponent
								responsive={true}
								hover={true}
								showSequence={true}
								pageSize={pageSize}
								pageNo={pageNo}
								columns={columns || []}
								data={transactions || []}
								onRowClick={onRowClick}
							/>
						)) ||
							null}
					</Row>
					<Row>
						<Pagination
							totalCount={totalCount || 0}
							viewCount={transactions?.length || 0}
							pageNo={pageNo}
							onChangePage={handlePageChange}
						/>
					</Row>
					{(viewAppointment && (
						<FullScreenPopup
							open={viewAppointment}
							onClose={() => closeOverlay()}
						>
							<ViewAppointment
								history={history}
								onClose={() => closeOverlay()}
								from="bookingTrans"
							/>
						</FullScreenPopup>
					)) ||
						null}
				</Container>
				{/* <ToastContainer /> */}
			</Hoc>
		</React.Fragment>
	);
};
export default Partners;
